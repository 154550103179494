
@import "../../bootstrap/variables.less";

@body-bg:					#ffff77;

@text-color:				#000;
@link-color:				#00f;
@link-hover-color:			#f00;
@link-hover-decoration:		none;

@font-family-base:			times new roman;
@font-size-base:			10px;
@line-height-base:			1;

@font-size-h1:				48px;
@headings-small-color:		#fff;

@thumb-width:				490px;
@thumb-height:				367px;
@thumb-margin-top:			0;
@thumb-margin-right:		0;
@thumb-margin-bottom:		0;
@thumb-margin-left:			0;
@thumb-border-width:		1px;
@thumb-border:				@thumb-border-width solid #000;
@thumb-hover-border:		@thumb-border-width solid #fff;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#444;
@popup-text-color:			#fff;
@popup-font-weight:			bold;

@social-links-margin:		5px 0;

@sites-list-font-size:				20px;
@sites-list-color:					#00f;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				30px;
@sites-list-title-weight:			normal;
@sites-list-title-decoration:		none;

@parent-alert-padding:		10px;
@parent-alert-margin:		20px auto;
@parent-alert-border:		1px solid #fff;
@parent-alert-font-size:	12px;
@parent-alert-color:		#ff7;
@parent-alert-bg:			#00f;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/parent_alert.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";

a {
	text-decoration: underline;
	cursor: pointer;
}
.red {
	color: #f00;
}
.blue {
	color: #00f;
}
#language-switcher {
	float: right;
	margin: 0 10px;
}
h1 {
	max-width: 850px;
	margin: 0 auto;
	text-align: center;
	font-weight: bold;
	a {
		text-decoration: none;
		&:hover {
			color: @link-color;
		}
	}
	small {
		font-weight: normal;
		font-size: 24px;
		color: @link-color;
	}
}
#nav {
	max-width: 850px;
	margin: 0 auto 30px;
	font-size: 18px;
	p {
		margin: 5px 0;
	}
	.blue-block {
		display: block;
		padding: 3px 0;
		background: #00f;
		text-align: center;
		color: #ff7;
		text-decoration: none;
		font-weight: bold;
		&:hover {
			color: #ff7;
		}
	}
	ul {
		margin: 5px 0;
		li {
			padding: 0;
		}
	}
}
.outro {
	font-size: 18px;
	margin: 18px 0;
}
#parent-alert {
	a {
		color: #ff0000;
	}
}